import alterpressApi from './base';
import {
    IAffiliateData,
    IAffiliateLevels,
    IAffiliateOrders,
    IAffiliatePupils,
} from '../../models/affiliate.model';

export const affiliateApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Affiliate'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getLevelsData: builder.query<IAffiliateLevels, void>({
                query: () => `affiliate/levels`,
            }),
            getAffiliateData: builder.query<
                IAffiliateData,
                {
                    year: string | number | null | undefined;
                    month: string | number | null | undefined;
                }
            >({
                query: ({ year, month }) => {
                    const params = new URLSearchParams();
                    if (year) {
                        params.set('year', year.toString());
                    }
                    if (month) {
                        params.set('month', month.toString());
                    }
                    const search = params.toString();
                    return `affiliate/details${search ? `?${search}` : ''}`;
                },
            }),
            getOrdersData: builder.query<
                IAffiliateOrders,
                {
                    year: string | number | null | undefined;
                    month: string | number | null | undefined;
                }
            >({
                query: ({ year, month }) => {
                    const params = new URLSearchParams();
                    if (year) {
                        params.set('year', year.toString());
                    }
                    if (month) {
                        params.set('month', month.toString());
                    }
                    const search = params.toString();
                    return `affiliate/orders${search ? `?${search}` : ''}`;
                },
            }),
            getPupilsData: builder.query<IAffiliatePupils, void>({
                query: () => `affiliate/pupils`,
            }),
            confirmRules: builder.mutation<undefined, Record<string, string>>({
                query: (data) => ({
                    url: `affiliate/confirm-rules`,
                    method: 'POST',
                    body: data,
                }),
            }),
        }),
    });

export const {
    useGetAffiliateDataQuery,
    useGetLevelsDataQuery,
    useGetPupilsDataQuery,
    useGetOrdersDataQuery,
    useConfirmRulesMutation,
} = affiliateApi;
