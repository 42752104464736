import { useMemo } from 'react';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { useConfirmRulesMutation } from '../redux/api/affiliate';

export const useAffiliate = () => {
    const t = useTranslations('RTKQueryApi');

    const [confirmRules, confirmRulesMutation] = useConfirmRulesMutation();

    const confirmRulesErrors = useMemo(() => {
        return getRtkQueryErrors(confirmRulesMutation.error, t.errors);
    }, [confirmRulesMutation.error, t.errors]);

    return {
        confirmRules: {
            fetch: confirmRules,
            data: confirmRulesMutation.data,
            isLoading: confirmRulesMutation.isLoading,
            isSuccess: confirmRulesMutation.isSuccess,
            isError: confirmRulesMutation.isError,
            errors: confirmRulesErrors,
        },
    };
};
