import SectionHeader from '../components/sections/header';
import SectionAffiliateProgramRules from '../components/sections/affiliate-program-rules';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import { useUser } from '../hooks/use-user';
import { useHasPermission } from '../hooks/use-has-permission';
import { usePagePathname } from '../hooks/use-page-pathname';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const User: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;
    const { mainSectionIndex } = getPageConfigFromSections(page.sections);
    const sections = getSectionsWithRepeatIndex(page.sections);
    const userState = useUser();
    const isAffiliateMember = useHasPermission(userState, 'AffiliateMember');
    const isRulesAccepted = useHasPermission(userState, 'AffiliatePanelAccess');
    const pathNames = usePagePathname();

    useEffect(() => {
        if (!userState.isLoggedIn || (userState.data && !isAffiliateMember)) {
            navigate('/', { replace: true });
        }
    }, [userState.isLoggedIn, userState, isAffiliateMember]);

    useEffect(() => {
        if (userState.data && !isRulesAccepted) {
            navigate(pathNames.affiliateProgramRules ? pathNames.affiliateProgramRules : '/');
        }
    }, [userState.data, isRulesAccepted]);

    return (
        <MainLayout>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default User;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'affiliate-program-rules': SectionAffiliateProgramRules,
    'footer': SectionFooter,
};